import loadcss from "loadcss";

window.loadCSS = loadcss;

/****************************************
 Form placeholder
 *****************************************/
function placeholderIsSupported() {
    let test = document.createElement('input');
    return ('placeholder' in test);
}

if (placeholderIsSupported()) {
    let placeholderSelector = '.inputtext, input[type="email"], .textarea';

    $(placeholderSelector).each(function () {
        let $label = $(this).find('label').detach(),
            $input = $(this).find('input, textarea').first();

        $input.attr('placeholder', $label.text().replace(/\:( \*)?$/, '$1'));
    });
}

/****************************************
 Menu afhandelimg
 *****************************************/
let trigger = $('.hamburger'),
    isClosed = true,
    $menu = $('.responsive-menu'),
    $overlay = $('.layout-overlay');

trigger.on('click', function () {
    menuAction();
});

$overlay.on('click', function () {
    menuAction();
});

function menuAction() {
    if (isClosed == false) {
        trigger.removeClass('is-active');
        // trigger.addClass('is-active');
        $overlay.removeClass('is-open');
        $menu.removeClass('open');
        isClosed = true;
    } else {
        // trigger.removeClass('is-active');
        trigger.addClass('is-active');
        $overlay.addClass('is-open');
        $menu.addClass('open');
        isClosed = false;
    }
}

/****************************************
 Top position
 *****************************************/
let $header = $('header');

let contentTopPosition = function () {
    let w = $(window).width();

    $menu.css('top', $header.outerHeight());
    if (w <= 1220) {
        $('.content-position').css('margin-top', $header.outerHeight());
    }
};

$(window).on('load', contentTopPosition);
$(window).on('resize', contentTopPosition);

/****************************************
 Collapse panel
 *****************************************/
import Velocity from "velocity-animate";
import "velocity-ui-pack";

$.fn.velocity = Velocity;

Velocity.RegisterEffect('fadeInPanels', {
    defaultDuration: 400,
    calls: [
        [{
            opacity: [1, 0.3],
            scale: [1, 0.5]
        }]
    ]
});

$('.accordion-link').on('click', function (e) {
    e.preventDefault();

    collapseThis($(this), e);
});

$('.element-template--responsive-menu  li.active.open').find('.expand').addClass('active').siblings('ul').css('display', 'block');

$('.element-template--responsive-menu .expand').on('click', function (e) {
    e.preventDefault();

    collapseThis($(this), e);
});

/*********************
 Image popup
 **********************/
require("magnific-popup");

let videoPopup = function ($el) {
    $el.find('.youtube-popup').magnificPopup({
        disableOn: 700,
        type: 'iframe',
        mainClass: 'mfp-fade',
        removalDelay: 160,
        preloader: false,
        fixedContentPos: false
    });
};

$('.element-template--video, .element-template--video-apple').each((key, el) => {
    videoPopup($(el));
});

fg.events.on('edit:links', function (el) {
    let $el = $(el);

    if ($el.hasClass('element-template--video')) {
        videoPopup($el);
    }

    if ($el.hasClass('element-template--video-apple')) {
        videoPopup($el);
    }
});

let simpleGal = function ($el) {
    $el.magnificPopup({
        delegate: 'a',
        type: 'image',
        tLoading: 'Loading image #%curr%...',
        mainClass: 'mfp-img-mobile',
        gallery: {
            enabled: true,
            navigateByImgClick: true,
            preload: [0, 1]
        }
    });
};

$('.element-template--gallery').each((key, el) => {
    simpleGal($(el));
});

fg.events.on('edit:images', function (el) {
    let $el = $(el);

    if ($el.hasClass('element-template--gallery')) {
        simpleGal($el);
    }
});


/*********************
 Story tell
 **********************/
require("swiper");

let initStorySwiper = function ($el) {

    let makeActive = function (swiper) {
        let active = $(swiper.slides[swiper.activeIndex]).data('hash');

        $el.find('a[data-hash="' + active + '"]').addClass('active').siblings().removeClass('active');
    };

    let thumbSwiper = new Swiper($el.find('.thumbs-container .swiper-container')[0], {
        nextButton: '.thumb-button-next',
        prevButton: '.thumb-button-prev',
        paginationClickable: true,
        slideToClickedSlide: true,
        slidesPerView: 3,
        spaceBetween: 90,
        hashnav: true,
        hashnavWatchState: true,
        centeredSlides: true,
        autoplay: 16000,
        onInit: makeActive,
        onSlideChangeStart: makeActive
    });

    let swiperImages = new Swiper($el.find('.swiper-container-images')[0], {
        pagination: '.swiper-pagination',
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev',
        paginationClickable: true,
        slideToClickedSlide: true,
        hashnav: true,
        hashnavWatchState: true,
        effect: 'fade',
        onInit: makeActive,
        onSlideChangeStart: makeActive
    });

    let swiperMessages = new Swiper($el.find('.swiper-container-messages')[0], {
        pagination: '.swiper-pagination',
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev',
        paginationClickable: true,
        slideToClickedSlide: true,
        hashnav: true,
        hashnavWatchState: true,
        onInit: makeActive,
        onSlideChangeStart: makeActive
    });

    $el.find('a[data-hash]').on("click", function (e) {
        e.preventDefault();

        swiperMessages.slideTo($(this).index());
        swiperImages.slideTo($(this).index());
        thumbSwiper.slideTo($(this).index());
    });
};

$('.mod-detail.element-template--story').each((key, el) => {
    initStorySwiper($(el));
});

fg.events.on('edit:detail', function (el) {
    let $el = $(el);

    if ($el.hasClass('element-template--story')) {
        initStorySwiper($el);
    }
});

let initLogoSwiper = function ($el) {
    let swiper = new Swiper($el.find('.swiper-container'), {
        pagination: '.swiper-pagination',
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev',
        paginationClickable: true,
        loop: true,
        autoplay: 4000,
        spaceBetween: 30,
        slidesPerView: 4,
        breakpoints: {
            1024: {
                slidesPerView: 3,
                spaceBetween: 30,
                slidesPerGroup: 3
            },
            768: {
                slidesPerView: 2,
                spaceBetween: 20,
                slidesPerGroup: 2
            },
            414: {
                slidesPerView: 1,
                spaceBetween: 10
            }
        }
    });
};

$('.mod-links.element-template--logos').each((key, el) => {
    initLogoSwiper($(el));
});

fg.events.on('edit:links', function (el) {
    let $el = $(el);

    if ($el.hasClass('element-template--logos')) {
        initLogoSwiper($el);
    }
});


/****************************************
 Tag-scroll functie
 *****************************************/
$('a[href="#top"]').on('click', function (e) {
    e.preventDefault();

    let target = $('section#top');

    $(target).velocity('scroll', {
        duration: 500,
        offset: -80,
        easing: 'ease-in-out'
    });
});

$('a[href="#direct"]').on('click', function (e) {
    e.preventDefault();

    let target = $('h2#direct');

    $(target).velocity('scroll', {
        duration: 500,
        offset: -150,
        easing: 'ease-in-out'
    });
});

/****************************************
 jQuery match-height
 *****************************************/
require("jquery-match-height");

$(window).on('load', function () {
    $('.mod-detail.element-template--columns .item').matchHeight();
    $('.mod-detail.element-template--columns-three .item').matchHeight();
    $('.mod-detail.element-template--results .item').matchHeight();
    $('.element-template--publication li .text').matchHeight();
    $('.blog-posts .blog-post').matchHeight();
    $('.element-template--small-overview .news-items .news-item').matchHeight();
});

/****************************************
 Accordion
 *****************************************/

Velocity.RegisterEffect('fadeInPanels', {
    defaultDuration: 400,
    calls: [
        [{
            opacity: [1, 0.3],
            scale: [1, 0.5]
        }]
    ]
});

function collapseThis($el, e) {
    let accordionWrapper = $el.closest('.panel__group'),
        accordionActivePanel = $el.closest('.panel__item'),
        accordionActiveLink = accordionWrapper.find('.active');

    if ($el.closest('.panel__item').find('.panel__content').hasClass('velocity-animating')) {
        return false;
    } else if (e.handled !== true) {
        accordionActiveLink.closest('.panel__item').find('.panel__content').attr('aria-expanded', false).velocity('slideUp', {
            easing: 'easeOutQuad'
        });
        if ($el.hasClass('active')) {
            $el.attr('aria-expanded', false).removeClass('active');
        } else {
            accordionActivePanel.find('.panel__content').attr('aria-expanded', true).velocity('slideDown', {
                easing: 'easeOutQuad'
            });
            accordionWrapper.find('.panel__toggle').removeClass('active').attr('aria-expanded', false);
            $el.attr('aria-expanded', true).addClass('active');
        }
        e.handled = true;
    } else {
        return false;
    }
}


$('.accordion-link').on('click', function (e) {
    e.preventDefault();

    collapseThis($(this), e);
});

$('.element-template--accordion  li.active.open').find('.expand').addClass('active').siblings('ul').css('display', 'block');

$('.element-template--accordion .expand').on('click', function (e) {
    e.preventDefault();

    collapseThis($(this), e);
});


/****************************************
 Tooltips
 *****************************************/
import "tooltipster";

$(window).on("load", function () {
    $('span.tooltip').tooltipster({
        theme: ['tooltipster-shadow', 'tooltipster-shadow-customized'],
        trigger: 'custom',
        triggerOpen: {
            mouseenter: true,
            tap: true
        },
        triggerClose: {
            mouseleave: true,
            originClick: true,
            tap: true
        }
    });
});

/****************************************
 Load google maps
 *****************************************/
const loadGoogleMapsAPI = require('load-google-maps-api');

loadGoogleMapsAPI({key: 'AIzaSyBWwElhxqyjYx2V2r8Gi2tv5vhJENLteEo'}).then(function (googleMaps) {
    //console.log(googleMaps) //=> Object { Animation: Object, ...

    let map = new google.maps.Map(document.getElementById('map'), {
        zoom: 7,
        center: new google.maps.LatLng(52.042455, 5.344394),
        mapTypeId: google.maps.MapTypeId.ROADMAP
    });

    let marker, i;

    let icons = {
        icon: basePath + '/images/pin.png'
    };

    for (i = 0; i < locations.length; i++) {
        marker = new google.maps.Marker({
            position: new google.maps.LatLng(locations[i]['lat'], locations[i]['long']),
            icon: icons['icon'].icon,
            map: map,
            ...locations[i],
        });

        google.maps.event.addListener(marker, 'click', function () {
            console.log('appel');
            $('#location-' + this.id).show().siblings().hide();
        });
    }
}).catch((err) => {
    // console.error(err)
});
